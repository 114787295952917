(function ($, generic) {
  $(document).on('product:waitlist:overlay', function (event, data) {
    Drupal.behaviors.productWaitlistOverlayV1.launch(data);
  });

  Drupal.behaviors.productWaitlistOverlayV1 = {
    launch: function (data) {
      var that = this;
      var userData = null;
      var rendered = site.template.get({
        name: 'product_waitlist_overlay',
        data: data
      });

      generic.overlay.launch({
        content: rendered,
        cssClass: 'product-waitlist-overlay-cbox',
        cssStyle: {
          width: '470px',
          height: Drupal.settings.enable_waitlist_soldout ? '400px' : '300px'
        },
        onComplete: function (response) {
          var $overlayCont = $('.waitlist-form__container');
          var $formCont = $('.js-waitlist-form', $overlayCont);
          var $submit = $('.js-waitlist-submit', $overlayCont);
          var $email = $('.js-waitlist-email', $overlayCont);
          var $requestType = $('.js-waitlist-request-type', $overlayCont);
          var $skuBaseId = $('.js-waitlist-sku-base-id', $overlayCont);
          var $successCont = $('.js-waitlist-success', $overlayCont);
          var $errorList = $('.js-error-messages', $overlayCont);
          var $waitlisthead = $('.js-waitlist-header', $overlayCont);
          var $pcEmailPromotion = $('input[name="PC_EMAIL_PROMOTIONS"]', $overlayCont);
          var $pcEmailPromotionContainer = $pcEmailPromotion.parents('.email_signup_checkbox');
          var headerText;
          // Set request type.
          var requestType = data.INVENTORY_STATUS == that.statusIds.inventory.soldOut ? 'BIS' : 'CS';

          $requestType.val(requestType);
          // Set sku_base_id in.
          $skuBaseId.val(data.SKU_BASE_ID);

          switch (data.INVENTORY_STATUS) {
            case 2:
              headerText = site.translations.product.temp_out_of_stock_msg;
              $waitlisthead.addClass('temp-out-of-stock');
              break;
            case 3:
              headerText = site.translations.product.coming_soon_stock_msg;
              break;
            case 7:
              headerText = site.translations.product.sold_out_stock_msg;
              break;
            default:
          }
          $waitlisthead.html(headerText);
          $formCont.on('submit', function (e) {
            e.preventDefault();

            var pObj = {};
            var params = $(this).serializeArray();

            for (var i = 0, len = params.length; i < len; i++) {
              pObj[params[i].name] = params[i].value;
            }
            generic.jsonrpc.fetch({
              method: 'form.get',
              params: [pObj],
              onSuccess: function (r) {
                $formCont.hide();
                $waitlisthead.hide();
                $successCont.show();
              },
              onFailure: function (r) {
                var errorObj = r.getError();
                var errorArray = errorObj.data.messages;

                generic.showErrors(errorArray, $errorList);
                if (Drupal.settings && Drupal.settings.resize_waitlist_height_on_error) {
                  $.colorbox.resize({ height: Drupal.settings.resize_waitlist_height_on_error });
                }
              }
            });
          });
          if (Drupal.settings.enable_waitlist_soldout) {
            generic.jsonrpc.fetch({
              method: 'user.json',
              onSuccess: function (jsonRpcResponse) {
                userData = jsonRpcResponse.getValue();
                if (userData.pc_email_optin && userData.signed_in) {
                  $pcEmailPromotion.prop('checked', true);
                  $pcEmailPromotionContainer.hide();
                }
              },
              onFailure: function (jsonRpcResponse) {
                userData = null;
              }
            });
          }
        }
      });
    },

    statusIds: {
      inventory: {
        comingSoon: 3,
        soldOut: 7
      },
      lifeOfProduct: {
        basicReorder: 2
      }
    }
  };
})(jQuery, generic);
